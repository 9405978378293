html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #2b2b2b;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #2b2b2b;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #2b2b2b;
  min-height: 100vh;
}

.whynot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  background-color: #2b2b2b;
}

.opacity-50 {
  opacity: 0.5;
}

.classes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.add-class-button {
  width: auto; /* Changed from fixed width */
  height: 40px;
  padding: 0 20px; /* Added horizontal padding */
  border-radius: 20px;
  background-color: #404040;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 8px; /* Adds space between + and text */
}

.add-class-button:hover {
  background-color: #505050;
}

.add-class-button p {
  font-size: 20px; /* Slightly smaller text */
  margin: 0;
  color: ivory;
}

.classes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Made boxes smaller */
  gap: 20px;
  width: 80%; /* Reduced from 100% to add margins */
  padding: 0 20px;
  margin: 0 auto; /* Centers the grid */
}

.class-item {
  aspect-ratio: 1;
}

.class-card {
  width: 100%;
  height: 100%;
  background-color: #404040;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  transition: all 0.2s ease;
  position: relative;
  margin: 10px;
}

.class-card.added {
  background-color: #5271A0;
}

.class-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.class-icon {
  font-size: 32px;
  color: ivory;
  margin-bottom: 10px;
}

.class-text {
  margin: 10px 0 5px;
  color: ivory;
  text-align: center;
  font-size: 16px;
}

.teacher-name {
  margin: 0;
  color: ivory;
  font-size: 14px;
  opacity: 0.8;
}

.toggle-icon-container {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
  background-color: #404040;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.class-card.added .toggle-icon-container {
  background-color: #3b5173;
}

.toggle-icon {
  color: ivory;
  font-size: 14px;
}

.navigator {
  flex: 1;
  background-color: #2b2b2b;
}

.WOTDActive {
  color:ivory;
  opacity:1;
}

.text {
  color: ivory;
  font-size: 20px;
  font-weight: bold;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.content {
  flex: 1;
  width: 100%;
}

.sign {
  padding: 10px;
  margin: 10px;
  text-align: center;
  border:none;
  background-color: #2b2b2b;
  color: ivory;
  border-radius: 10px;
  font-size: 20px;
}

.mustbenice {
  align-self: center;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 80%;
  background-color: #2b2b2b;
}

.sign_in_butt {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  width: 20%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #2b2b2b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_butt_task {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  width: 40%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #2b2b2b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_butt_task_new {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  width: 90%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #2b2b2b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_butt_inital {
  font-family:  Work Sans, sans-serif;
  align-self: center;

  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #F3B04C;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.reset_password_butt {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  padding: 10px;
  color: ivory;
  background-color: #B95A34;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.new_here {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  font-weight: bold;

  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #2b2b2b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_create {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  padding: 10px;
  color: ivory;
  background-color: #5271A0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}


.sign_in_butt_inital_inverse {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #5271A0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_create_inverse {
  font-family:  Work Sans, sans-serif;
  align-self: center;
  width: 40%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #F3B04C;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_butt_inital_real {
  box-sizing: border-box; /* Add this line */
  font-family: Work Sans, sans-serif;
  align-self: center;
  width: 15%;
  margin-right: 20px;
  padding: 10px;
  color: ivory;
  background-color: #F3B04C;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 100%;
  font-weight: bold;
 
}


.sick {
  color: #2b2b2b;
  font-family:  Work Sans, sans-serif;
  width: 20%;
  padding: 10px;
  color: ivory;
  background-color: #2b2b2b;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.sign_in_butt_public {
  font-family: Work Sans, sans-serif;
  width: 20%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #5271A0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.iwanttoslammyheadagainstthewall {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.iwanttoslammyheadagainstthewalluntillicannotthinkatall {

  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.drain {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  width: 30%;
  margin-left: 20px;
  background-color: #2b2b2b;
  

}

.pain {
  box-sizing: border-box;
  width: 30%;
  background-color: #2b2b2b;
  text-align: right; /* Add this line */
}


.onefifteen {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;
  width: 0%;
  background-color: #5271A0;
  

}

.sign_in_butt_private {
  font-family: Work Sans, sans-serif;
  width: 20%;
  margin: 10px;
  padding: 10px;
  color: ivory;
  background-color: #B95A34;
  border: none;
  border-radius: 10px;
  cursor: pointer;


}

.header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #2b2b2b; 
  width: 100%;
}

.do_date_headers {
  text-align: center;
  color: ivory;
  margin-left: 2.5%;
  margin-right: 2.5%;
  margin-bottom: 2.5%;
  font-size: 40px;
  font-weight: bold;

}


.do_date_header{
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 25%;
  margin-right: 5%;
  background-color: #2b2b2b;
  border: none;
  font-size: 25px;
  font-weight: bold;
  width: 20%;

}

.due_date_header{
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 25%;
  background-color: #2b2b2b;
  border: none;
  font-size: 25px;
  font-weight: bold;
  width: 20%;

}

.kan_ban_headers {
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 25%;
  margin-right: 5%;
  background-color: #2b2b2b;
  border: none;
  font-size: 40px;
  font-weight: bold;
  width: 20%;

}

.calendar_header {
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 25%;
  background-color: #2b2b2b;
  border: none;
  font-size: 40px;
  font-weight: bold;
  width: 20%;

}



.student_kan_ban_headers {
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 10%;
  margin-right: 5%;
  width: 20%;
  background-color: #2b2b2b;
  border: none;
  font-size: 40px;
  font-weight: bold;
  width: 20%;

}

.student_calendar_header {
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 5%;
  width: 20%;
  background-color: #2b2b2b;
  border: none;
  font-size: 40px;
  font-weight: bold;
  width: 20%;

}

.statistics_header {
  text-align: center;
  color: ivory;
  margin-top: 0.5%;
  margin-bottom: 1%;
  margin-left: 5%;
  margin-right: 10%;
  width: 20%;
  background-color: #2b2b2b;
  border: none;
  font-size: 40px;
  font-weight: bold;
  width: 20%;
}

.construct{
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #2b2b2b;
  align-items: center;


}

.quercus {
  width: 100%;
  align-items: center;
  align-content: center;
  background-color: #2b2b2b;

}

.calendar_container{
  display: flex;
  justify-content: center;
  width: 90%;

  background-color: #2b2b2b;

}

.day{
  display: flex;
  flex-direction: column;
  width: 14.2857142857%;
  background-color: #393939;
  border-radius: 10px;
  align-items: center;
  margin: 1px;
  min-height: 70px;
  padding-bottom: 10px;



}

.day_not_month{
  display: flex;
  flex-direction: column;
  width: 14.2857142857%;
  background-color: #2b2b2b;
  border-color: #393939;
  border-width: 2px;
  border-radius: 10px;
  align-items: center;
  margin: 1px;
  min-height: 70px;
  padding-bottom: 10px;



}

.day_number{
  width:90%;
  color: ivory;
  font-size: 20px;
  font-weight: bold;


  
}

.month_name{
  width:70%;
  color: ivory;
  font-size: 30px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}
.month_button{
  width:15%;
  color: ivory;
  font-size: 30px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #2b2b2b;
  border: none;
}

.day_container{
  display: flex;
  flex-direction: column;
  width: 14.2857142857%;
  background-color: #393939;
  border-radius: 10px;
  align-items: center;
  margin: 1px;

}

.event-cell {
  height: auto !important;
  min-height: 30px;
  overflow: auto;
}


.warning {
  color: #B95A34;
  font-size: 20px;
}

.created {
  color: #7B8D53;
  font-size: 20px;
}

.text {
  color: ivory;
  font-size: 30px;
  font-weight: bold;
}

.calendar_task_container_todo{
  background-color: #B95A34;
  width: 80%;
  border-radius: 10px;
  margin: 2px;
  color: ivory;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;

}
.calendar_task_container_doing{
  background-color: #F3B04C;
  width: 80%;
  border-radius: 10px;
  margin: 2px;
  color: ivory;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;

}
.calendar_task_container_done{
  background-color: #5271A0;
  width: 80%;
  border-radius: 10px;
  margin: 2px;
  color: ivory;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 5px;

}

.day_titles{
  color: ivory;
  font-size: 20px;
  font-weight: bold;

}

.sun-icon, .moon-icon {
  font-size: 30px;
  background-color: #2b2b2b; 
  

}


.logo {
  width: 525px;
  height:150px;
  cursor: pointer;
}

.profile_pic {
  margin-top: 40px;
  margin-left: 10px;
  width: 10%;
  height: 10%;
  border-radius: 20px;
  margin: 10px;
  margin-bottom: 20px;
  margin-left: 5%;
}

.username {
  margin-top: 40px;
  color: ivory;
  font-size: 50px;
  font-weight: bold;
  margin: 10px;
}

.routines_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  justify-content: space-around;
}

.class_icon {
  font-size: 90px;
  margin-bottom: 20px;
}

.things {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
}

.class_name {
  color: ivory;
  font-size: 10vw;
  font-weight: bold;
  margin: 10px;
}

.teacher_name {
  color: ivory;
  font-size: 7.5vw;
  margin: 10px;
}

.classes {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: ivory;
  font-size: 20px;
  margin: 10px;
  font-weight: bold;
}

.icons {
  margin-right: 25px;
  margin: 10px;
  color: ivory;
  font-size: 20px;
  align-items: center;
}

.current_tasks {
  background-color: #5271A0;
  width: 70%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.container2 {
  flex: 1;
  background-color: #2b2b2b;
  justify-content: center;
}

.aha {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.iwishwenevermet {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.haha {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

}



.actual_current_tasks {
  color: ivory;
  font-size: 20px;
  padding: 0.01%;
  font-weight: bold;
  padding-top: 0;
  padding-left: 5%;
  margin: 1%;
}

.waste {
  display: grid;
  grid-template-columns: repeat(5, minmax(150px, 1fr)); /* Changed to fixed 5 columns */
  gap: 20px;
  width: 90%; /* Increased to better use space with fixed columns */
  padding: 0 20px;
  margin: 0 auto;
  justify-content: center; /* Centers the grid */
}

.wotd-item {
  aspect-ratio: 1;
  transition: transform 0.2s;
}

.wotd-item:hover {
  transform: scale(1.05);
}

.add-wotd-button {
  width: 100%;
  height: 100%;
  background-color: #404040;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
}

.add-wotd-button:hover {
  transform: scale(1.05);
  background-color: #505050;
}

.add-wotd-icon {
  font-size: 32px;
  color: ivory;
  margin-bottom: 10px;
}

.add-wotd-text {
  color: ivory;
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.current_task_display {
  color: ivory;
  font-size: 15px;
  opacity: 0.5;
  font-weight: bold;
  padding: 10px;
  padding-bottom: 0;
  padding-left: 5%;
}



.profile_wotds {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin: 10px;
}

.add_wotd {

  width: 80%;
  height: 80%;
  background-color: #F3B04C;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign_out {

  width: 30%;
  height: 30%;
  background-color: #F3B04C;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sign_out_butt{
  align-self: center;
  color: ivory;
  padding: 0;
  background-color: #5271A0;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 90%;
}

.sign_out_butt p {
  color: ivory;
  font-size: 30px; 
  font-weight: bold; 
  margin: 10px;
  justify-content: center;
  display: flex;
  margin-bottom: 5px;
}

.sign_in_butt_public p {
  color: ivory;
  font-size: 30px; 
  font-weight: bold; 
  margin: 10px;
  justify-content: center;
  display: flex;
}

.sign_in_butt p {
  color: ivory;
  font-size: 30px; 
  font-weight: bold; 
  margin: 10px;
  justify-content: center;
  display: flex;
}

.sign_in_butt_private p {
  color: ivory;
  font-size: 30px; 
  font-weight: bold; 
  margin: 10px;
  justify-content: center;
  display: flex;
}



.delete_butt{
  align-self: center;
  color: ivory;
  padding: 0;
  background-color: #B95A34;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 90%;
  margin-top: 5px;
}

.delete_butt p {
  color: ivory;
  font-size: 30px; 
  font-weight: bold; 
  margin: 10px;
  justify-content: center;
  display: flex;
}



.task_and_routines_container {
  display: flex;
  justify-content: center;

  background-color: #2b2b2b;

  

}

.board_containers {
  width: 20%;
  display: flex;
  flex-direction: column;
  margin: 10px;
  align-items: center;


  background-color: #2b2b2b;
}



.THEFUTUREOFHELLBELONGSTOTHEVEES {
  width: 50%;
  height: 30%;
  background-color: #F3B04C;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.followers_text {
  color: ivory;
  font-size: 20px;
  font-weight: bold;
}

.modal {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.perchanse {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_box {
  background-color: #2b2b2b;
  width: 50%;
  height: 60%;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_image_box {
  background-color: grey;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  align-items: "center";
  justify-content: "center";
  text-align: "center";
}

.description {
  box-sizing: border-box;
  align-self: center;
  padding: 10px;
  border: none;
  background-color: ivory;
  color: #2b2b2b;
  width: 50%;
  font-size: 30px;
  text-align: center;
  border-radius: 10px;
  margin: 5px;
}

.input_container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.almighty_container {
  background-color: #2b2b2b;
  height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
}

.mystic_container {
  flex: 1;
  background-color: #2b2b2b;
  height: 100vh;
  align-content: center;
  align-items: center;
  align-self: center; 
}

.to_the_right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.scroll_container {
  width: 100%;
  height:70%;
  background-color: #F3B04C;
  border-radius: 10px;
  margin: 3.75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.scroll_image {
  width: 80%;
  height: 60%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll_username {
  color: ivory;
  font-size: 25px;
  font-weight: bold;
}

.welcome{
  color:ivory;
  
}

.button_ish {
  background-color: #F3B04C;
  border: none;
}

.ivory_background_buttions {
  background-color: ivory;
  border: none;
}

.scroll_description {
  width: 85%;
  background-color: ivory;
  border-radius: 10px;
  color: #2b2b2b;
  font-size: 20px;
  padding: 2%;
}

.description_text {
  color: #2b2b2b;
  font-size: 15px;
}

.tags {
  background-color: #F3B04C;
  padding: 5px;
  border-radius: 10px;
  color: ivory;
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
}

.username_and_other {
  width: 85%;
  height: 5%;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flatlist {
  width:35%;
  height: 1000px;
  align-self: center;
  overflow-y: auto;
 
}

.vision_board_image {
  width: 40%;
  height: 22.5%;
  border-radius: 10px;
  margin: 0.85%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flatlist_vision_board {
  width: 90%;
  height: 155%;
  align-self: center;
  align-content: center;
}

.mother {
  align-self: center;
  justify-content: center;
  align-items: center;
}

.navbar {
  background-color: #2b2b2b;
  height: 10%;
}

.task_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: grey;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  width: 80%;
}

.edit_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #2b2b2b;
  border-radius: 10px;
  padding: 10px;
  width: 80%;
}

.habit_container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: left;
  background-color: #2b2b2b;
  border-radius: 10px;
  margin: 5px;
  padding: 0;
  width: 60%;
}

.todo_container {
  background-color: #B95A34;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  width: 100%;
  border: none;
  color: ivory;
  font-size: 30px;
  font-weight: bold;
}

.doing_container {
  background-color: #F3B04C;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  width: 100%;
  border: none;
  color: ivory;
  font-size: 30px;
  font-weight: bold;

}

.done_container {
  background-color: #5271A0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin: 5px;
  padding: 5px;
  width: 100%;
  border: none;
  color: ivory;
  font-size: 30px;
  font-weight: bold;
}

.add_task_container {
  background-color: grey;
  flex-direction: row;

  border-radius: 10px;

  padding: 5px;
  margin-left: 20%;
  margin-right: 20%;
  width: 60%;
  justify-content: center;
  border-radius: 10px;
  border: none;
  color: ivory;
  font-size: 30px;
  font-weight: bold;
}

.new_task {
  display: flex; /* Add this line */
  background-color: grey;
  flex-direction: row;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  width: 90%;
  justify-content: center;
  border-radius: 10px;
  border: none;
  color: ivory;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.2s ease; /* Added transition */
  cursor: pointer;
}

.new_task:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.task_header{

  width: 80%;
  align-content: center;
  align-items: center;
}
.task_header_done{
  width: 90%;
  align-content: center;
  align-items: center;
}
.task_details{

  width: 10%;
  align-content: center;
  align-items: center;
}
  
.task_details_row{

  width: 100%;
  align-content: center;
  flex-direction: row;
  display:flex;
  align-items: center;
  justify-content: center;
  align-self: center;


}

.move-button{
  background-color: transparent;
  border: none;
}


.add_task_new_container {
  width: 100%;
  align-items: center;
  align-content: center;
  background-color: #2b2b2b;
  padding: 10px 0; /* Reduced padding */
  margin: 0; /* Remove any margin */
}

/* For the AddClass page specifically */
.addclass-page.add_task_new_container {
  padding-top: 60px; /* Keep the larger padding only for AddClass page */
}

.add_habit_container {
  background-color: grey;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  padding: 2px;

  padding-left: 10px;
  width: 85%;
}

.date_picker_container{
  display: flex; /* Change this */
  width: 30%;
  align-items: center;
  justify-content: center;
  background-color: ivory;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  text-align: center; /* Add this */
}
.date_picker_container_titles{
  display: flex; /* Change this */
  width: 30%;
  align-items: center;
  justify-content: center;
  background-color: ivory;
  margin-top:10px;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
  margin-right:10px;
  text-align: center; /* Add this */
}

.date_pucker{
  width: 90%;
  align-content: center;
  background-color: ivory;
  border:none;
}

.submit_routine_container {
  align-self: center;
  background-color: #F3B04C;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
  padding: 2px;
  width: 50%;
}

.select_container {
  width:40%;
  margin:10px;
}

.pomodoro_select_container {
  width: 150px !important; /* Increased from default */
  margin: 0 10px !important;
}

.pomodoro_select_container .select__control {
  background-color: #2b2b2b !important;
  border-color: ivory !important;
  min-height: 45px !important; /* Increased height */
}

.pomodoro_select_container .select__single-value {
  color: ivory !important;
  font-size: 18px !important; /* Increased font size */
}

.pomodoro_select_container .select__menu {
  background-color: #2b2b2b !important;
  font-size: 18px !important; /* Increased font size */
}

.pomodoro_select_container .select__option {
  padding: 12px !important; /* Increased padding */
}

.modal_text {
  color: ivory;
  font-size: 35px;
  font-weight: bold;
}

.task_text {
  color: ivory;
  font-size: 2.5%;
}

.habit_text {
  color: ivory;
  font-size: 3%;
  font-weight: bold;
  margin-left: 5%;
}

.task_text_username_inbox {
  color: #F3B04C;
  font-size: 2.5%;
  font-weight: bold;
}

.followers_button {
  background-color: #5271A0;
  padding: 5px;
  border-radius: 10px;
  margin-left: 5px;
}

.inbox_container{
  width: 90%;
  align-items: center;
  align-self: center;
}

.followers_button_text {
  color: ivory;
  font-size: 2%;
  font-weight: bold;
}

.other_text {
  color: ivory;
  font-size: 30px;
  font-weight: bold;
}

.add_task_title {
  color: ivory;
  font-size: 5%;
  font-weight: bold;
}

.actuall_add_task_container {
  width: 40%; /* Increased from 30% to 40% to give more space */
  background-color: #F3B04C;
  border-radius: 10px;
  margin: 5% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.add_task_input {
  width: 30%;
  height: 10%;
  background-color: ivory;
  border-radius: 10px;
  margin: 1%;
  padding: 5px;
  align-content: center;
  align-items: center;
}

.assign_date_container {
  background-color: ivory;
  padding: 10px;
  text-align: center;
}

.assight_task_title_container {
  background-color: ivory;
  padding: 10px;
  width: 80% !important; /* Increased from 65% to 80% */
}

.new_habit_container {
  background-color: grey;
  padding: 2px;
  width: 65%;
}

.add_class_container {
  border: none;
  align-self: center;

  background-color: grey;
  justify-content: space-between;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  font-family:  Work Sans, sans-serif;
  width: 20%;
  color: ivory;
  cursor: pointer;
}


.top_class_display {
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.teacher_names {
  color: ivory;
  font-size: 15px;
}

.add_class_input {
  background-color: ivory;
  width: 45% !important; /* Make both inputs equal width */
  padding: 5px;
  margin: 5px;
  text-align: center;
}

.create_class_container {
  background-color: grey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  margin:5;
  padding: 10;
  width: 0.85%;
  margin-top:  0.05%;
  margin-bottom: 0.05%;
}


/* WOTD Modal styles */
.wotd-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.wotd-modal-content {
  position: relative;
  max-width: 50vw; /* Reduced from 60% to 50% of viewport width */
  max-height: 80vh; /* Added max height */
  background-color: #333333;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.wotd-modal-image {
  max-width: 100%;
  max-height: 60vh; /* Reduced from 80vh to 60vh */
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 20px;
}

.wotd-modal-details {
  color: ivory;
  padding: 15px 0;
}

.wotd-modal-description {
  font-size: 18px;
  margin-bottom: 10px;
}

.wotd-modal-tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  font-size: 14px;
  color: #999;
  margin-top: 10px;
}

.tag {
  font-weight: bold;
  color: ivory;
  background-color: #F3B04C;
  padding: 4px 8px;
  border-radius: 4px;
}

.modal-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(64, 64, 64, 0.7);
  color: ivory;
  border: none;
  border-radius: 25px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.modal-nav-button:hover {
  background: rgba(80, 80, 80, 0.9);
}

.modal-nav-button.left {
  left: -100px;
}

.modal-nav-button.right {
  right: -100px;
}

/* Add WOTD Modal */
.add_wotd {
  background-color: #F3B04C; /* Changed to yellow */
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.image_box {
  width: 300px;
  height: 300px;
  background-color: #2b2b2b; /* Changed from #404040 */
  border-radius: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.image_box:hover {
  background-color: #404040;
}

.description {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background-color: #2b2b2b; /* Changed from #404040 */
  color: ivory;
}

/* Fixed button sizing */
.sign_in_butt_public,
.sign_in_butt_private {
  min-width: 100px; /* Added minimum width */
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #5271A0;
  color: ivory;
  font-size: 16px; /* Added explicit font size */
  white-space: nowrap; /* Prevents text wrapping */
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_in_butt_private {
  background-color: #B95A34;
}

/* Button container */
.iwanttoslammyheadagainstthewall {
  display: flex;
  gap: 20px; /* Increased gap between buttons */
  margin-top: 20px;
  padding: 10px; /* Added padding around buttons */
}

/* Public/Private button */
.sign_in_butt_public,
.sign_in_butt_private {
  min-width: 120px; /* Increased minimum width */
  padding: 12px 24px; /* Increased padding */
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #5271A0;
  color: ivory;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Submit button */
.sign_in_butt {
  min-width: 120px; /* Match other button width */
  padding: 12px 24px; /* Match other button padding */
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #2b2b2b;
  color: ivory;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign_in_butt_private {
  background-color: #B95A34;
}

/* Button text */
.text {
  margin: 0;
  padding: 0;
  color: ivory;
  font-size: 16px;
  font-weight: bold;
}

/* Task hover animations */
.new_task {
  display: flex;
  background-color: grey;
  flex-direction: row;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
  width: 90%;
  justify-content: center;
  border-radius: 10px;
  border: none;
  color: ivory;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.2s ease; /* Added transition */
  cursor: pointer;
}

.new_task:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Calendar task hover animations */
.calendar_task_container_todo,
.calendar_task_container_doing,
.calendar_task_container_done {
  transition: all 0.2s ease;
  cursor: pointer;
}

.calendar_task_container_todo:hover,
.calendar_task_container_doing:hover,
.calendar_task_container_done:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Add Task button */
.add_task_container {
  background-color: #404040;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  color: ivory;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add_task_container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* View toggle buttons */
.kan_ban_headers,
.student_kan_ban_headers,
.calendar_header,
.student_calendar_header,
.statistics_header,
.do_date_header,
.due_date_header {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.kan_ban_headers:hover,
.student_kan_ban_headers:hover,
.calendar_header:hover,
.student_calendar_header:hover,
.statistics_header:hover,
.do_date_header:hover,
.due_date_header:hover {
  transform: scale(1.02);
}

/* Calendar navigation buttons */
.month_button {
  padding: 5px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.month_button:hover {
  transform: scale(1.02);
}

.student-search {
  width: 50%;
  background-color: #2b2b2b;
  color: ivory;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  border-color: ivory;
  border-width: 1px;
  cursor: pointer;
}

.cancel-button {
  background-color: #B95A34;
  color: ivory;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.username_display {
  font-size: 30px;
  font-weight: bold;
  color: #F3B04C;
}

/* Pomodoro start/stop button */
.pomodoro-button {
  font-size: 25px;
  color: ivory;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pomodoro-button:hover {
  transform: scale(1.1);
}

/* Task items in both Kanban and Calendar views */
.new_task,
.calendar_task_container_todo,
.calendar_task_container_doing,
.calendar_task_container_done {
  transition: all 0.2s ease;
  cursor: pointer;
}

.new_task:hover,
.calendar_task_container_todo:hover,
.calendar_task_container_doing:hover,
.calendar_task_container_done:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* View toggle buttons */
.kan_ban_headers,
.calendar_header,
.do_date_header,
.due_date_header {
  transition: all 0.2s ease;
  cursor: pointer;
}

.kan_ban_headers:hover,
.calendar_header:hover,
.do_date_header:hover,
.due_date_header:hover {
  transform: scale(1.02);
}

/* Calendar navigation buttons */
.month_button {
  transition: all 0.2s ease;
  cursor: pointer;
}

.month_button:hover {
  transform: scale(1.1);
}

/* Task action buttons */
.move-button {
  transition: all 0.2s ease;
  cursor: pointer;
}

.move-button:hover {
  transform: scale(1.1);
}

/* Add Task button */
.add_task_container {
  transition: all 0.2s ease;
  cursor: pointer;
}

.add_task_container:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Navigation buttons (wins/classes) */
.sign_in_butt {
  transition: all 0.2s ease;
  cursor: pointer;
}

.sign_in_butt:hover {
  transform: scale(1.02);
}

/* Sign out button and modal buttons */
.sign_out_butt,
.delete_butt {
  transition: all 0.2s ease;
  cursor: pointer;
}

.sign_out_butt:hover,
.delete_butt:hover {
  transform: scale(1.02);
}

/* Modal navigation buttons */
.modal-nav-button {
  transition: all 0.2s ease;
  cursor: pointer;
}

.modal-nav-button:hover {
  transform: scale(1.1);
}

/* AddClass specific styles */
.add_task_new_container {
    position: relative;
    background-color: #2b2b2b;
    min-height: 100vh;
    padding-top: 60px; /* Make room for back button */
}

.back-button {
    position: fixed;
    top: 80px;
    left: 12vw;
    background-color: #2b2b2b;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    text-align: left;

}

/* Grid specifically for AddClass */
.add_task_new_container .classes-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
    padding: 20px;
    width: 90%;
    margin: 0 auto;
}

.class-card.added {
  background-color: #5271A0; /* Blue background for added classes */
}


.add_task_new_container .class-item {
    aspect-ratio: 1;
    padding: 5px;
    max-width: 120px;
    margin: 0 auto;
}

.add_task_new_container .class-card {
    width: 100%;
    height: 100%;
    background-color: #404040;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    transition: all 0.2s ease;
    position: relative;
}

.add_task_new_container .class-card.added {
    background-color: #5271A0 !important; /* Blue background for added classes */
}

.add_task_new_container .toggle-icon-container {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 32px;
    background-color: inherit; /* Inherits parent's background color */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_task_new_container .toggle-icon {
    color: ivory;
    font-size: 22px; /* Slightly larger */
}

.add_task_new_container .class-icon {
    font-size: 32px; /* Larger icon */
    margin: 12px 0;
}

.add_task_new_container .class-text {
    margin: 8px 0 4px;
    color: ivory;
    text-align: center;
    font-size: 16px; /* Larger text */
}

.add_task_new_container .teacher-name {
    margin: 0;
    color: ivory;
    font-size: 14px; /* Larger text */
    opacity: 0.8;
}

/* Specific styles for TaskView modal dropdowns */
.task_details_container .select_container {
    width: 150px ; /* Wider dropdown */
}

/* Override any React-Select inline styles */
.task_details_container .select_container > div {
    min-width: 150px ;
}

/* Update modal container and inputs */
.actuall_add_task_container {
    width: 30%; 
    background-color: #F3B04C;
    border-radius: 10px;
    margin: 5% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

/* Center the input row */
.input_row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
}

/* Update submit button */
.submit_routine_container {
    background-color: #2b2b2b !important;
    color: ivory;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin-top: 15px;
    cursor: pointer;
    width: 50%;
}

.pomodoro-select {
  width: 200px;
  margin: 0 auto;
  text-align: center;
}

.pomodoro-select .select__control {
  margin: 10px auto;
}

.pomodoro-start-button{
  font-size: 25;
  color: 'ivory';
  font-weight: 'bold';
  background-color: "transparent";
  border: "none";
  cursor: "pointer";
}

.pomodoro-change-length{
  font-size: 25;
  color: 'ivory';
  font-weight: 'bold';
  background-color: "transparent";
  border: "none";
  cursor: "pointer";
}



/* Add these new animation styles */
@keyframes barAnimation {
  0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 80%;
  }
  75% {
    height: 95%;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}

.bar-container {
  animation: barAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: bottom;
}

/* Add this class to both day and week view bar containers */
.statistics-graph-container {
  transition: all 0.3s ease;
}

.delete-student-button {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.confirm-delete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.confirm-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.confirm-buttons button {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #4a4a4a;
  color: ivory;
}

.class-item {
  position: relative;
}

.new_task {
  cursor: pointer;
  transition: transform 0.2s;
}

.new_task:hover {
  transform: scale(1.02);
}

